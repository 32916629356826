body {
  background-color: #fcf8e5;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
  Helvetica, Arial, "Lucida Grande", sans-serif;
}

h1, h2, h3, h4 {
  font-family: 'Times New Roman';
}


/* Login Page */

.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.login-container {
  text-align: center;
  margin: auto;
  max-width: 30%;
}

.login-container button {
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.login-header {
  display: flex;
  justify-content: center;
  padding-top: 2%;
}

.login-header img {
  width: 120%;
  height: auto;
  padding-right: 10px;
  padding-top: 50px;
}

/* Login/Signup Form */

.login-signup-form input[type="text"],
.login-signup-form input[type="password"],
.login-signup-form button[type="submit"] {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #000000;
  background: transparent;
  margin-bottom: 18px;
  outline: none;
  font-size: 16px;
  border-radius: 0;
  box-shadow: none;

}

.login-signup-form input[type="text"]::placeholder,
.login-signup-form input[type="password"]::placeholder {
  color: black;
}

.login-signup-form input[type='file'] {
  margin-bottom: 4px;
  padding: 10px;
}

.login-signup-form label {
  font-size: 14px;
}

.login-signup-form button[type="submit"] {
  margin-top: 10px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  border-radius: 0;
  box-shadow: none;
}

.login-signup-form input[type="submit"]:hover {
  background-color: #3c3c3c;
}

.login-signup-form select {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #000000;
  background: transparent;
  margin-bottom: 18px;
  outline: none;
  font-size: 16px;
}

.login-signup-form input[type="file"] {
  display: none;
}

.login-signup-form label.custom-file-upload {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #000000;
  background: transparent;
  margin-bottom: 18px;
  outline: none;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  position: relative;
  color: #000000;
}

.login-signup-form label.custom-file-upload:hover {
  background: rgba(0, 0, 0, 0.1);
}


/* NavBar */

.navbar {
  padding: 20px 40px;
  display: flex;
  align-items: center;
}

.navbar a,
.navbar button {
  background-color: #7e5454;
  border: none;
  padding: 10px;
  color: #fff;
  text-decoration: none;
  margin: 0 15px;
  font-size: 16px;
  line-height: 1.5;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
  Helvetica, Arial, "Lucida Grande", sans-serif;
}

.navbar a:hover,
.navbar button:hover {
  background-color: #a97979;
  cursor: pointer;
}

.navbar img{
  width: 170px;
  padding-right: 30px;
}

.dropdown-menu {
  display: none;
}

/* Home */

.home{
  margin: 10px;
}
.home h1,
.home h2 {
  text-align: center;
}


/* Leaderboards */

.leaderboards {
  text-align: center;
}
.leaderboard-team-card {
  width: 80%;
  border: 2px solid black;
  background-color: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
}

.leaderboard-team-card a {
  text-decoration: none;
  color: black;
}

.leaderboard-team-card a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.leaderboard-team-card h1 {
  margin: 0;
  padding: 20px;
}

.leaderboard-1st {
  background-color: gold;
}

.leaderboard-2nd{
  background-color: silver;
}

.leaderboard-3rd {
  background-color: #CD7F32;
}


/* Climbing Sets */

.climbing-sets-main h1 {
  text-align: center;
}

.climbing-sets-container {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}

.climbing-sets {
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
  width: calc(33.33% - 20px);
  box-sizing: border-box;
}

.climbing-sets img {
  display: block;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 100%;
  height: 70%;
}

.climbing-sets-details-btn {
  margin-top: 10px;
  height:40px;
  background-color: #7e5454;
  color: #fff;
  border: none;
  border-radius: 0px;
  cursor: pointer;
}

/* Climbing Set Details */

.climbing-set-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.climbing-set-details {
  text-align: center;
  border-radius: 5px;
  width: 60%;
}

.climbing-set-details img  {
  max-width: 60%;
  border-radius: 5px;
}

/* Climbs Form */

.climbs {
  border: 2px solid black;
  background-color: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  width: 60%
}

.climbs input {
  transform: scale(1.2);
  margin-left: 10px;
}

.climbs-form button[type="submit"]  {
  width: 60%;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 0px;
  cursor: pointer;
}

.climbs-form input[type="submit"]:hover {
  background-color: #3c3c3c;
}

.grasshopper-climbs {
  display: flex;
  align-items: center;
}

.view-climb-link {
  margin-right: 40px;
  text-decoration: none;
}

.view-climb-button {
  padding: 5px 10px;
  height: 40px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
}

/* .inactive-form {
  opacity: 0.5;
  pointer-events: none;
} 

I could render the form visibitly based on end date????
*/



/* Team Sign Up */

.team-sign-up-container {
  text-align: center;
}

.create-team input[type="text"] {
  box-sizing: border-box;
  width: 25%;
  height: 40px;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #000000;
  background: transparent;
  margin-bottom: 18px;
  outline: none;
  font-size: 16px;
  border-radius: 0;
  box-shadow: none;
}

.create-team button[type="submit"] {
  width: 25%;
  height: 40px;
  margin-top: 10px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 0px;
  cursor: pointer;
}

.create-team button[type="submit"]:hover {
  background-color: #3c3c3c;
}

.find-team input[type="text"] {
  box-sizing: border-box;
  width: 25%;
  height: 40px;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #000000;
  background: transparent;
  margin-bottom: 18px;
  outline: none;
  font-size: 16px;
  border-radius: 0;
  box-shadow: none;
}

.find-team-card {
  border: 2px solid black;
  background-color: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  width: 25%;
  height: 40px;
  text-decoration: none;
  color: black;
  font-size: 10px;
}

.find-team-card:hover {
  text-decoration: none;
  color: black;
  background-color: #d3d3d3;
}

/* Profile */

.profile-header img{
  width: 125px;
  height: 125px;
  border-radius: 25%;
  margin-right: 20px;
}

.profile-header {
  margin-top: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.profile-subheader {
  border: 2px solid black;
  background-color: #efefef;
  align-items: center;
  justify-content: center;
  margin-left: 20%;
  margin-right: 20%;
  border-radius: 5px;
}

.profile button {
  background-color: #7e5454;
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  text-decoration: none;
  margin: 0 15px;
  font-size: 10px;
  line-height: 1;
  cursor: pointer;
}

.profile-header button:hover {
  background-color: #a97979;
  cursor: pointer;
}

.profile {
  text-align: center;
}

.week-set-container {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: top;
  
}

.week-set h2 {
  text-align: center;
}

.week-set {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-width: 300px;
  text-align: left;
  border: 2px solid black;
  background-color: #efefef;
  border-radius: 5px;
}

/* Edit Profile */

.edit-profile {
  text-align: center;
}

.edit-profile input {
  box-sizing: border-box;
  width: 25%;
  height: 40px;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #000000;
  background: transparent;
  margin-bottom: 18px;
  outline: none;
  font-size: 16px;
  border-radius: 0;
  box-shadow: none;
}

.edit-profile select {
  box-sizing: border-box;
  width: 25%;
  height: 40px;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #000000;
  background: transparent;
  margin-bottom: 18px;
  outline: none;
  font-size: 16px;
  border-radius: 0;
  box-shadow: none;
}

.edit-profile label{
  text-align: left;
}

.edit-profile button[type='submit'] {
  width: 25%;
  height: 40px;
  margin-top: 10px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 0px;
  cursor: pointer;
}

.edit-profile button[type='submit']:hover {
  background-color: #3c3c3c;
}

.edit-profile input[type="file"] {
  display: none;
}

.edit-profile label.custom-file-upload {
  box-sizing: border-box;
  width: 25%;
  height: 40px;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #000000;
  background: transparent;
  margin-bottom: 18px;
  outline: none;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  position: relative;
  color: #000000;
}

.edit-profile label.custom-file-upload:hover {
  background: rgba(0, 0, 0, 0.1);
}

/* Team Details */

.team-details {
  text-align: center;
  border: 2px solid black;
  border-radius: 5px;
  background-color: #d4d4d4;
  margin: 20px auto;
  max-width: 500px;
}

.team-member {
  border: 2px solid black;
  border-radius: 5px;
  background-color: #efefef;
  margin: 20px auto;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-member img{
  width: 100px;
  margin-right: 50px;
  width: 125px;
  height: 125px;
  border-radius: 25%;
}

.team-member-info {
  text-align: left;
  width: 150px;
}

.team-details button {
  box-sizing: border-box;
  width: 80%;
  height: 40px;
  margin-top: 10px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  margin-bottom: 20px;
}

.team-details button:hover {
  background-color: #3c3c3c;
}

/* Admin Tools */

.admin-tools {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.admin-tools a{
  border: 1px solid #2e7fc7;
  background-color: #2e7fc7;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px;
  margin-bottom: 10px;
  text-decoration: none;
}

.admin-tools a:hover {
  background-color: #0074d9;
}

.admin-climbing-sets-container {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: top;
  width: 95%;
  gap: 20px;
}

.admin-climbing-sets-container button{
  border: 1px solid #2e7fc7;
  background-color: #2e7fc7;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 5px;
  height: auto
}

.admin-climbing-sets-container button:hover {
  background-color: #0074d9;
}

.admin-climbing-set {
  border: 2px solid black;
  border-radius: 5px;
  background-color: #efefef;
  margin: 20px auto;
  width: 20%;
}

.admin-climbs{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.admin-climbs h3 {
  margin-right: 10px;
}

.admin-set-edit-delete {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.admin-set-edit-delete button{
  background-color: rgb(234, 7, 7);
}

.admin-set-edit-delete button:hover{
  background-color: rgb(195, 35, 35);
}

.admin-set-edit-delete a,
.admin-set-edit-delete button {
  border: none;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  line-height: 1.5;
  padding: 5px;
  margin: 0 0 10px 0;
  font-size: 13px;
  cursor: pointer;
}


/* Admin New Climb Form  */


.admin-new-climb-form select {
  box-sizing: border-box;
  width: 40%;
  height: 40px;
  padding-left: 10px;
  margin: 10px 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.admin-new-climb-form button[type="submit"] {
  box-sizing: border-box;
  width: 40%;
  height: 30px;
  border: 1px solid #2e7fc7;
  background-color: #2e7fc7;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.admin-new-climb-form button[type="submit"]:hover {
  background-color: #0074d9;
}

.admin-new-climb-form input {
  box-sizing: border-box;
  width: 80%;
  height: 35px;
  border-radius: 5px;
}

/* Admin New Climbing Set  */

.admin-new-climbing-set {
  text-align: center;
  margin: 20px auto;
  border: 2px solid black;
  border-radius: 5px;
  background-color: #efefef;
  width: 40%;
  font-size: 16px;
}

.admin-new-climbing-set input[type='text'] {
  margin-bottom: 10px;
  width: 40%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.admin-new-climbing-set input[type='date'] {
  margin-bottom: 20px;
  width: 40%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.admin-new-climbing-set input[type='file'] {
  margin-bottom: 10px;
  width: 40%;
  padding: 10px;
}

.admin-new-climbing-set select {
  margin-bottom: 20px;
  box-sizing: border-box;
  width: 40%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.admin-new-climbing-set textarea {
  margin-bottom: 20px;
  width: 300px;
  height: 100px;
}

.admin-new-climbing-set button[type="submit"] {
  box-sizing: border-box;
  width: 40%;
  height: 30px;
  border: 1px solid #2e7fc7;
  background-color: #2e7fc7;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  margin-bottom: 20px;
}

.admin-new-climbing-set button[type="submit"]:hover {
  background-color: #0074d9;
}

.admin-user-filters {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
}

.admin-user-filters h2 {
  margin: 0;
}

.admin-user-filters input[type="text"] {
  margin-right: 20px;
  box-sizing: border-box;
  width: 20%;
  border: none;
  border-bottom: 2px solid #000000;
  background: transparent;
  outline: none;
  border-radius: 0;
  box-shadow: none;
}

.admin-user-filters input[type="checkbox"] {
  margin-left: 10px;
}


.admin-user table {
  width: 100%;
  border-collapse: collapse;
}

.admin-user th, td {
  padding: 10px 20px;
  text-align: left;
}

.admin-user th {
  background-color: #f2f2f2;
}

.admin-user td {
  border-bottom: 1px solid #ddd;
}

/* Access Denied */

.access-denied {
  text-align: center;
  width: 40%;
  margin: 0 auto;
}


/* Password Reset Form */

.password-reset-form {
  margin-top: 100px;
  text-align: center;
}
.password-reset-form input {
  box-sizing: border-box;
  width: 30%;
  height: 40px;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #000000;
  background: transparent;
  margin-bottom: 18px;
  outline: none;
  font-size: 16px;
  border-radius: 0;
  box-shadow: none;
}

.password-reset-form img {
  width: 8%;
  margin-bottom: 20px;
}

.password-reset-form button[type="submit"] {
  width: 30%;
  height: 40px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  border-radius: 0;
  box-shadow: none;
}

.password-reset-form input[type="password"]::placeholder {
  color: black;
}
/* Loading */

.loading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
}

.loading .spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border-left-color: rgb(255, 255, 255);
  animation: spin 1s ease infinite;
  display: inline-block;
  box-sizing: border-box;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {

  .login-container {
    text-align: center;
    padding: 20px;
    max-width: 90%;
  }

  .navbar {
    display: none;
  }
  .dropdown-menu {
    display: block;
  }

  .dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown select{
    cursor: pointer;
    background-color: #7e5454;
    border: none;
    border-radius: 5px;
    color: #fff;
    padding: 5px;
    font-size: 16px;
    line-height: 1.5;
    width: 245px;
    text-align: center;
  }
  
  .dropdown-menu img {
    width: 80%;
    margin-top: 5%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 3%;
  }

  .leaderboard-team-card {
    width: 90%;
    margin-bottom: -10px;
  }

  .leaderboard-team-card h1 {
    width: 90%;
    font-size: 18px;
  }

  .climbing-sets-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .climbing-sets {
    border: 2px solid black;
    border-radius: 5px;
    margin: 10px;
    font-size: 13px;
    width: 90%;
    box-sizing: border-box;
  }
  
  .climbing-sets img {
    margin-bottom: 10px;
    border-radius: 5px;
    width: 100%;
    height: auto;
  }

  .team-details {
    text-align: center;
    border: 2px solid black;
    border-radius: 5px;
    background-color: #d4d4d4;
    margin: 10px;
  }
  
  .team-member {
    border: 2px solid black;
    border-radius: 5px;
    background-color: #efefef;
    margin: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .team-member img{
    margin-right: 30px;
  }
  
  .team-member-info {
    text-align: left;
    width: 150px;
  }
  
  .team-details button {
    box-sizing: border-box;
    width: 80%;
    height: 40px;
    margin-top: 10px;
    background-color: #000000;
    color: #fff;
    border: none;
    border-radius: 0px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .team-details button:hover {
    background-color: #3c3c3c;
  }

  .climbing-set-details {
    text-align: center;
    border-radius: 5px;
    width: 90%;
  }
  
  .climbing-set-details img  {
    max-width: 90%;
    border-radius: 5px;
  }

  .climbs {
    width: 90%;
  }

  .climbs-form button[type="submit"]  {
    width: 90%;
  }

  .view-climb-link {
    margin-right: 10px;
  }

  .create-team input[type="text"] {
    width: 80%;
  }
  
  .create-team button[type="submit"] {
    width: 80%;
  }
  
  .create-team button[type="submit"]:hover {
    background-color: #3c3c3c;
  }
  
  .find-team input[type="text"] {
    width: 80%;
  }
  
  .find-team-card {
    width: 80%;
  }

  .profile-header img{
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .profile-header {
    flex-direction: column;
  }
  
  .profile {
    text-align: center;
  }

  .profile-subheader {
    margin-left: 10%;
    margin-right: 10%;
  }
  
  .week-set-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .week-set {
    width: 90%;
  }

  .edit-profile input {
    width: 90%;
  }
  
  .edit-profile select {
    width: 90%;
  }
  
  .edit-profile label{
    text-align: left;
  }
  
  .edit-profile label.custom-file-upload {
    width: 90%;
  }

  .edit-profile button[type='submit'] {
    width: 90%;
    margin-bottom: 20px;
  }

  .video-overlay {
    display: none;
  }

  .password-reset-form input {
    width: 80%;
  }
  
  .password-reset-form img {
    width: 25%;
    margin-bottom: 20px;
  }
  
  .password-reset-form button[type="submit"] {
    width: 80%;
  }
  
}

